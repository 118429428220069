import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useGetDownload from '../../hooks/useGetDownload'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    display: flex;
    max-width: 720px;
    padding: 90px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .p-section-download {
      width: 100%;
      display: flex;
      margin: 0 auto 60px;
      justify-content: center;
    }

    .ul-download {
      max-width: 720px;

      li {
        width: 100%;
        display: flex;
        margin: 0 auto 50px;
        justify-content: center;
        background: #ffffff;
        border: #cccccc solid 1px;
        padding: 25px;

        &:last-of-type {
          margin: 0 auto 0;
        }

        .download-img-wrap {
          width: 278px;
          padding: 0;
          margin: 0 20px 0 0;

          img {
            display: block;
          }
        }

        .download-txt-wrap {
          margin: 0 0 0 auto;

          .bar-title {
            color: #00b5a6;
            font-weight: 600;
            font-size: 1.625em;
            margin-bottom: 13px;
          }

          > p {
            margin-bottom: 13px;
          }

          .btn-wrap {
            text-align: right;

            .p-btn-download {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .ul-download {
      max-width: 720px;

      li {
        flex-wrap: wrap;

        .download-img-wrap {
          width: 100%;
          margin: 0 !important;

          img {
            display: block;
            width: 100%;
            height: 100px;
            object-fit: cover;
          }
        }

        .download-txt-wrap {
          margin: 20px 0 0 0 !important;
          text-align: center;

          > p {
            margin-bottom: 20px !important;
          }

          .btn-wrap {
            text-align: center !important;
          }
        }
      }
    }
  }
`

/**
 * サービスサイト資料ダウンロード_フォーム入力後
 * @constructor
 * PDFのデータ更新する場合は、青木さんに依頼すべし。（我らでは無理です）
 */
const officialVariousDownloadContent = () => {
  const { download, loading } = useGetDownload()

  if (loading) return <></>
  const onClickDownload = (url: string) => async () => {
    const splits = (url.split('?')[0] ?? '').split('/')
    const fileName = decodeURI(splits[splits.length - 1] ?? 'file.pdf')
    const response = await fetch(url)
    const blob = await response.blob()
    const newBlob = new Blob([blob])
    const objUrl = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = objUrl
    link.download = fileName
    link.click()
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl)
    }, 250)
  }

  return (
    <Wrapper>
      <div className="p-section-inner">
        <div className="p-section-download">
          <ul className="ul-download">
            {/* 製品パンフレット */}
            <li>
              <div className="download-img-wrap">
                <img
                  src="/images/official/feature/imgDownload01.png"
                  alt="製品パンフレット"
                />
              </div>
              <div className="download-txt-wrap">
                <div className="bar-title">製品パンフレット</div>
                <p>
                  キンクラの主要な機能の一覧や導入メリットをご覧いただけます
                </p>
                <div className="btn-wrap">
                  <OfficialCommonBtn
                    size="large"
                    className="p-btn-download"
                    onClick={onClickDownload(download.pamphletUrl)}
                  >
                    ダウンロード
                    <span className="txt-size-small">（1.1MB）</span>
                  </OfficialCommonBtn>
                </div>
              </div>
            </li>
            {/* 製品紹介スライド */}
            <li>
              <div className="download-img-wrap">
                <img
                  src="/images/official/feature/imgDownload02.png"
                  alt="製品紹介スライド"
                />
              </div>
              <div className="download-txt-wrap">
                <div className="bar-title">製品紹介スライド</div>
                <p>
                  企業の勤怠管理のお悩みに対してどうお役に立てるかをまとめました
                </p>
                <div className="btn-wrap">
                  <OfficialCommonBtn
                    size="large"
                    className="p-btn-download"
                    onClick={onClickDownload(download.introductionUrl)}
                  >
                    ダウンロード
                    <span className="txt-size-small">（506KB）</span>
                  </OfficialCommonBtn>
                </div>
              </div>
            </li>
            {/* ご利用イメージ画面 */}
            <li>
              <div className="download-img-wrap">
                <img
                  src="/images/official/feature/imgDownload03.png"
                  alt="ご利用イメージ画面"
                />
              </div>
              <div className="download-txt-wrap">
                <div className="bar-title">ご利用イメージ画面</div>
                <p>
                  キンクラの全ての機能の画面とその役割をスライド
                  でご覧いただけます
                </p>
                <div className="btn-wrap">
                  <OfficialCommonBtn
                    size="large"
                    className="p-btn-download"
                    onClick={onClickDownload(download.usingUrl)}
                  >
                    ダウンロード
                    <span className="txt-size-small">（4.8MB）</span>
                  </OfficialCommonBtn>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  )
}

export default officialVariousDownloadContent
