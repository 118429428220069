import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby-link'
import useApi from './useApi'
import useRouteParam from './useRouteParam'

type IDownload = {
  /** ご利用イメージ画面資料URL */
  usingUrl: string
  /** 製品パンフレットURL */
  pamphletUrl: string
  /** 製品紹介資料URL */
  introductionUrl: string
}
const InitDownload: IDownload = {
  introductionUrl: '',
  pamphletUrl: '',
  usingUrl: '',
}
type IReturn = {
  /** ダウンロード情報 */
  download: IDownload
  /** ローディングフラグ */
  loading: boolean
}

/**
 * 資料ダウンロード関連のフック関数
 */
const useGetDownload = (): IReturn => {
  const [download, setDownload] = useState<IDownload>(InitDownload)
  const [loading, setLoading] = useState(true)
  const { execApi } = useApi('get')
  const location = useLocation()
  const { id } = queryString.parse(location.search)
  const routeParam = useRouteParam('/download?expired=1')

  useEffect(() => {
    if (!id) {
      navigate(routeParam)
      return
    }

    execApi(
      `/dl2022?id=${id}`,
      {},
      (response) => {
        setLoading(false)
        setDownload(response as IDownload)
      },
      () => {
        navigate('/download?expired=1')
      }
    )
  }, [])

  return {
    download,
    loading,
  }
}

export default useGetDownload
