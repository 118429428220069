import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialContactLayout from '../components/organisms/officialContactLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialVariousDownloadContent from '../components/organisms/officialVariousDownloadContent'
import BreakPoints from '../styles/breakPoints'

const Wrapper = styled.main`
  font-size: 16px;
  .p-head-inner {
    .p-nav-area {
      display: none;
    }
  }
  ${BreakPoints.xLarge} {
    .p-head-inner {
      .p-nav-area {
        display: flex;
      }
    }
  }
`

/**
 * 資料ダウンロードページ_フォーム入力後
 * @constructor
 */
const VariousDownloadPage = () => (
  <OfficialContactLayout>
    <SEO title="ホワイトペーパー" />
    <Wrapper>
      <OfficialLowHeader
        label="ホワイトペーパー"
        description="下記リンクから各種資料のダウンロードができます"
      />
      <OfficialVariousDownloadContent />
    </Wrapper>
  </OfficialContactLayout>
)

export default VariousDownloadPage

export const pageQuery = graphql`
  query VariousDownloadPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
